import { CustomInput } from './input';
import React, { forwardRef, useEffect, Ref, useState, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Column, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  Table as MantineTable,
  Pagination,
  useMantineTheme,
  Flex,
  Text,
  TextInput,
  Button,
  Checkbox,
  Box,
  Badge,
} from '@mantine/core';
import { FiArrowDown, FiArrowUp, FiMoreVertical, FiSearch } from 'react-icons/fi';

export interface Props {
  children?: React.ReactNode;
  columns: Column[];
  data: any[];
  isFilters?: any;
  sort?: boolean;
  actions?: {
    edit: boolean;
    delete: boolean;
  };
  paginationSettings?: {
    enablePagination: boolean;
    pageSize: number;
  };
  globalSearch?: boolean;
  showRowSelect?: boolean;
  onRowClick?: (row: any) => void;
  pageCount?: number;
  currentPage?: number;
  isLoading?: boolean;
  onPageChange?: (page: number) => void;
  onChangeSort?: (sort: any) => void;
}

const IndeterminateCheckbox = forwardRef<HTMLInputElement, { indeterminate: boolean }>(
  ({ indeterminate, ...rest }, ref: Ref<HTMLInputElement>) => {
    const defaultRef = React.useRef<HTMLInputElement>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      //@ts-ignore
      if (resolvedRef.current) {
        //@ts-ignore
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [indeterminate, resolvedRef]);

    return (
      // <input
      //   type="checkbox"
      //   className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-primary focus:ring-2 focus:ring-red-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-red-600"
      //   ref={resolvedRef}
      //   {...rest}
      // />
      <Checkbox indeterminate ref={resolvedRef} {...rest} />
    );
  },
);

IndeterminateCheckbox.displayName = 'IndeterminateCheckbox';

export const Table: React.FC<Props> = ({
  children,
  columns,
  data,
  isLoading,
  sort = false,
  actions = { edit: true, delete: true },
  globalSearch = false,
  showRowSelect = false,
  paginationSettings = { enablePagination: false, pageSize: data?.length },
  onRowClick,
  pageCount,
  onPageChange,
  onChangeSort,
  currentPage,
}) => {
  const theme = useMantineTheme();
  const [editingRow, setEditingRow] = useState(null);

  const [tableData, setTableData] = useState(data);

  const finalTableData = React.useMemo(() => (isLoading ? Array(10).fill({}) : data), [isLoading, data]);
  const tableColumns = React.useMemo(
    () =>
      isLoading
        ? columns.map(column => ({
            ...column,
            Cell: () => <Skeleton />,
          }))
        : columns,
    [isLoading, columns],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
    page,
    nextPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { globalFilter, pageIndex, sortBy },
  } = useTable(
    {
      columns: tableColumns,
      data: finalTableData,
      initialState: { pageIndex: 0 },
      manualPagination: true, // We'll handle pagination ourselves.
      manualSortBy: true,
      pageCount: pageCount || 1,
      plugins: [useGlobalFilter, useFilters, useSortBy, usePagination, useRowSelect],
    },
    useGlobalFilter,
    useFilters,
    sort ? useSortBy : undefined,
    usePagination,
    useRowSelect,
    hooks => {
      if (showRowSelect) {
        hooks.visibleColumns.push((columns: any) => [
          {
            id: 'selection',
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
          },
          ...columns,
        ]);
      }
    },
  );

  // Only consider having search results if we're not loading and have data
  const hasSearchResults = !isLoading && page && page.length > 0;
  const handleEdit = (row: any) => {
    // Update the editingRow state variable
    setEditingRow(row);
  };

  const handleDelete = (row: any) => {
    // Remove the row from the data array
    setTableData((old: any) => old.filter((r: any) => r.id !== row.original.id));

    // If the deleted row was currently being edited, unset the editingRow state variable
    if (editingRow && editingRow.original.id === row.original.id) {
      setEditingRow(null);
    }
  };
  const handleDeleteRows = () => {
    // Get the selected rows
    const selectedRows = selectedFlatRows.map((row: any) => row.original);

    // Delete the selected rows
    const newData = tableData.filter(row => !selectedRows.includes(row));

    // Update the data
    setTableData(newData);
  };

  const handleChangePage = (page: any) => {
    onPageChange && onPageChange(page);
  };

  const memoizedPagination = useMemo(() => {
    return pageCount ? <Pagination m="xl" total={pageCount} value={currentPage} onChange={handleChangePage} /> : null;
  }, [
    canNextPage,
    canPreviousPage,
    nextPage,
    pageIndex,
    pageOptions.length,
    paginationSettings.enablePagination,
    currentPage,
    pageCount,
  ]);

  const content = () => <Button onClick={handleDeleteRows}>Delete Item {`(${selectedFlatRows.length})`}</Button>;

  const actionContent = (row: any) => (
    <Box px="sm">
      <Box>
        {actions.edit &&
          (editingRow && editingRow.id === row.id ? (
            <Button onClick={() => setEditingRow(null)} mr="sm">
              Save
            </Button>
          ) : (
            <Button onClick={() => handleEdit(row)} mr="sm">
              Edit
            </Button>
          ))}
      </Box>

      <Box>{actions.delete && <button onClick={() => handleDelete(row)}>Delete</button>}</Box>
    </Box>
  );

  const handleRowClick = (row: any) => {
    if (onRowClick) {
      onRowClick(row.original);
    }
  };

  const handleGlobalFilterChange = e => {
    const value = e.target.value || undefined;
    setGlobalFilter(value);
  };

  const handleOnChangeSort = React.useCallback(() => {
    return onChangeSort && onChangeSort(sortBy);
  }, [sortBy]);

  // Store new sort state in reducer and call API to fetch new data from server
  useEffect(() => {
    handleOnChangeSort();
  }, [handleOnChangeSort]);

  return (
    <>
      {children}
      {globalSearch && (
        <CustomInput
          placeholder="Search"
          icon={<FiSearch />}
          onChange={handleGlobalFilterChange}
          value={globalFilter}
        />
      )}
      <MantineTable.ScrollContainer minWidth={1080} type="native">
        <MantineTable
          horizontalSpacing="xl"
          layout="fixed"
          verticalSpacing="md"
          {...getTableProps()}
          styles={{
            tr: {
              borderColor: theme.colors.gray[1],
              cursor: 'pointer',
            },
          }}
        >
          <MantineTable.Thead>
            {headerGroups.map(headerGroup => (
              <MantineTable.Tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <MantineTable.Th key={column.id} {...column.getHeaderProps(sort && column.getSortByToggleProps())}>
                    {column.render('Header')}
                    {sort && column.isSorted ? column.isSortedDesc ? <FiArrowDown /> : <FiArrowUp /> : ''}
                  </MantineTable.Th>
                ))}
                {actions.edit || actions.delete ? (
                  <MantineTable.Th key="action">
                    <Flex justify="center">
                      <Text>Action</Text>

                      <Badge variant="transparent">{selectedFlatRows.length}</Badge>

                      {showRowSelect && (
                        <>
                          {/* @ts-ignore */}
                          {/* <Popover content={content} placement="right"> */}

                          <FiMoreVertical />

                          {/* </Popover> */}
                        </>
                      )}
                    </Flex>
                  </MantineTable.Th>
                ) : null}
              </MantineTable.Tr>
            ))}
          </MantineTable.Thead>
          <MantineTable.Tbody {...getTableBodyProps()}>
            {!isLoading && (!page || page.length === 0) && (
              <MantineTable.Tr key="no-results">
                <MantineTable.Td colSpan={headerGroups[0]?.headers?.length || 1} align="center">
                  <Text c="dimmed" size="sm">No results found</Text>
                </MantineTable.Td>
              </MantineTable.Tr>
            )}
            {(isLoading || hasSearchResults) &&
              page.map((row: any) => {
                prepareRow(row);
                return (
                  <MantineTable.Tr key={row.id} {...row.getRowProps()} onClick={() => handleRowClick(row)}>
                    {row.cells.map((cell: any) => {
                      return (
                        <MantineTable.Td key={cell.id} {...cell.getCellProps()}>
                          {cell.column.id !== 'selection' &&
                          editingRow &&
                          // @ts-ignore

                          editingRow.id === row.id ? (
                            <TextInput
                              value={cell.value}
                              onChange={e => {
                                const newValue = e.target.value;
                                setTableData((old: any) => {
                                  const newData = [...old];
                                  newData[row.index][cell.column.id] = newValue;
                                  return newData;
                                });
                              }}
                            />
                          ) : (
                            <span>{cell.render('Cell')}</span>
                          )}
                        </MantineTable.Td>
                      );
                    })}
                    {actions.edit || actions.delete ? (
                      <MantineTable.Td key={`action-${row.id}`}>
                        {/* <Popover
                          //  @ts-ignore
                          content={() => actionContent(row)}
                          placement="right"
                        >
                          <FiMoreVertical className="!h-5 !w-5 cursor-pointer !text-gray-500" />
                        </Popover> */}
                      </MantineTable.Td>
                    ) : null}
                  </MantineTable.Tr>
                );
              })}
          </MantineTable.Tbody>
        </MantineTable>
      </MantineTable.ScrollContainer>
      <div>{memoizedPagination}</div>
    </>
  );
};
